import React, { Component } from 'react';
import { Layout } from 'layouts';
import {
    PageHeader,
    TechnologyPartners,
    BrandsWorkedWithUs
} from '@components';
import {
    BetterThanOther,
    MultiColumnList
} from '@components/industries';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/industries';

class Event extends Component {
    render() {
        const finTech = pageContent.subPage.finTech;
        return (
            <Layout>
                <PageHeader image={finTech.headerImage} />

                <Heading
                    title={finTech.title}
                    overview={finTech.overview}
                    noHeadingIcon={true} />

                <BetterThanOther content={finTech.betterThanOther} />

                <MultiColumnList content={finTech.loanAndMutualFundApps} listStyle={'dot'} listTitleHighlighted={true} />

                <MultiColumnList content={finTech.onlineBankingApps} listStyle={'dot'} listTitleHighlighted={true} />

                <MultiColumnList content={finTech.eWalletApps} listStyle={'dot'} listTitleHighlighted={true} />

                <MultiColumnList content={finTech.bitcoinCryptocurrencyApps} listStyle={'dot'} listTitleHighlighted={true} />

                <PageNavigation
                    prev={pageContent.navigation.prev}
                    next={pageContent.navigation.next} />

                <TechnologyPartners />

                <BrandsWorkedWithUs />

            </Layout>
        );
    }
}

export default Event;
